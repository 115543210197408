import axios from 'axios'
import Vue from 'vue'
//import router from './router'

//axios.defaults.baseURL = "https://8.130.91.200:1337"
const http = axios.create({
    headers: {
        "Content-Type": "application/json;charset=UTF-8"
    },
    baseURL: "https://admin.bonatini.com"
})

http.interceptors.request.use((config) => {
    return config
}, (error) => {
    return Promise.reject(error)
})

http.interceptors.response.use(res => {//拦截器
    return res
}, err => {
    console.log(err)
    if (err.response.data.message) {
        Vue.prototype.$message({
            type: 'error',
            message: err.response.data.message
        })
    }
    if (err.response.status === 401) {
       // router.push('/login')
    }
})
export default http
