<template>
    <div>
        <div>
            <button @click="printEditorHtml">save</button>
        </div>
        <div style="border: 1px solid #ccc; margin-top: 10px;">
            <!-- 工具栏 -->
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
            />
            <!-- 编辑器 -->
            <Editor
                style="height: 80vh; overflow-y: hidden;"
                :defaultConfig="editorConfig"
                v-model="html"
                @onChange="onChange"
                @onCreated="onCreated"
            />
        </div>
        <!-- <div style="margin-top: 10px;">
            <textarea
                v-model="html"
                readonly
                style="width: 100%; height: 200px; outline: none;"
            ></textarea>
        </div> -->
    </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
    name: 'MyEditor',
    components: { Editor, Toolbar },
    data() {
        return {
            editor: null,
            html: '<p>hello&nbsp;world</p>',
            toolbarConfig: {
                // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
                // excludeKeys: [ /* 隐藏哪些菜单 */ ],
            },
            editorConfig: {
                placeholder: 'Please Enter Your Description...',
                // autoFocus: false,

                // 所有的菜单配置，都要在 MENU_CONF 属性下
                MENU_CONF: {}
            },
            proId:null
        }
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
        },
        onChange(editor) {
            console.log('onChange', editor.getHtml()) // onChange 时获取编辑器最新内容
        },
        insertTextHandler() {
            const editor = this.editor
            if (editor == null) return
            editor.insertText(' Please Enter Your Description...')
        },
        async getInfo(){
            this.data = await this.$http.get(`/api/products/${this.proId}`, {
                data: {
                    
                }
            })
           this.html= this.data.data.data.attributes.description
        },
        async printEditorHtml() {
           
            const editor = this.editor
            
            if (editor == null) return
            console.log(editor.getHtml())
             const res = await this.$http.put(`/api/products/${this.proId}`, {
                data: {
                    description: editor.getHtml()
                }
            })
            console.log(res)
        },
        disableHandler() {
            const editor = this.editor
            if (editor == null) return
            editor.disable()
        }
    },
    mounted() {
        this.proId = window.location.href.split('?id=')[1]
        setTimeout(()=>{
            this.getInfo()
        },1500)
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁 editor ，重要！！！
    },
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
